define("shared/components/cluster-driver/driver-eks/component", ["exports", "jquery", "semver", "shared/mixins/cluster-driver", "shared/utils/amazon", "shared/utils/util", "ui/models/cluster", "shared/components/cluster-driver/driver-eks/template"], function (_exports, _jquery, _semver, _clusterDriver, _amazon, _util, _cluster, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    versionChoiceService: Ember.inject.service('version-choices'),
    layout: _template.default,
    // config computed prop is setup in the clusterDriver mixin on init
    configField: 'eksConfig',
    step: 1,
    serviceRoles: null,
    securityGroups: null,
    whichSecurityGroup: 'default',
    errors: null,
    otherErrors: null,
    clusterErrors: null,
    serviceRoleMode: 'default',
    vpcSubnetMode: 'default',
    allImages: null,
    allSecurityGroups: null,
    allKeyPairs: null,
    allLaunchTemplates: null,
    selectedServiceRole: null,
    selectedGroupedDetails: null,
    kmsKeys: null,
    instanceTypes: null,
    cloudWatchAuditEnabled: false,
    cloudWatchApiEnabled: false,
    cloudWatchSchedulerEnabled: false,
    cloudWatchControllerManagerEnabled: false,
    cloudWatchAuthenticatorEnabled: false,
    loadFailedKmsKeys: false,
    isPostSave: false,
    regionChoices: _amazon.EKS_REGIONS,
    kubernetesVersionContent: _amazon.EKS_VERSIONS,
    isNew: Ember.computed.not('editing'),
    isCustomSecurityGroup: Ember.computed.equal('whichSecurityGroup', 'custom'),
    editing: Ember.computed.equal('mode', 'edit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),
    allErrors: Ember.computed.union('errors', 'otherErrors', 'clusterErrors'),

    init() {
      this._super(...arguments);

      const {
        clusterIsPending,
        editing,
        model: {
          cloudCredentials
        }
      } = this;
      Ember.setProperties(this, {
        allSubnets: [],
        clients: {},
        clusterErrors: [],
        errors: [],
        kmsKeys: [],
        otherErrors: [],
        instanceTypes: []
      });
      let config = Ember.get(this, 'cluster.eksConfig');

      if (!config) {
        const kubernetesVersion = this.kubernetesVersionContent.firstObject;
        const ngConfig = { ..._cluster.DEFAULT_NODE_GROUP_CONFIG
        };
        Ember.set(ngConfig, 'launchTemplate', this.globalStore.createRecord({
          name: '',
          id: null,
          version: null,
          type: 'launchTemplate'
        }));
        Ember.set(ngConfig, 'version', kubernetesVersion);
        Ember.setProperties(_cluster.DEFAULT_EKS_CONFIG, {
          kubernetesVersion,
          nodeGroups: [this.globalStore.createRecord(ngConfig)]
        });
        config = this.globalStore.createRecord(_cluster.DEFAULT_EKS_CONFIG);

        if (!Ember.isEmpty(cloudCredentials)) {
          const singleMatch = cloudCredentials.find(cc => {
            if (!Ember.isEmpty(Ember.get(cc, 'amazonec2credentialConfig'))) {
              return true;
            }
          });

          if (singleMatch) {
            Ember.set(config, 'amazonCredentialSecret', singleMatch.id);
          }
        }

        Ember.set(this, 'cluster.eksConfig', config);
      } else {
        if (editing && clusterIsPending) {
          Ember.set(this, 'step', 5);
        } else {
          var _config;

          this.syncUpstreamConfig();
          const initalTags = { ...(config.tags || {})
          };
          Ember.set(this, 'initalTags', initalTags);

          if (this.mode === 'edit') {
            (config.loggingTypes || []).forEach(option => {
              Ember.set(this, `cloudWatch${Ember.String.classify(option)}Enabled`, true);
            });
          }

          if (!Ember.isEmpty((_config = config) === null || _config === void 0 ? void 0 : _config.subnets)) {
            Ember.set(this, 'vpcSubnetMode', 'custom');
          }
        }
      }
    },

    willDestroyElement() {
      Ember.setProperties(this, {
        step: 1,
        clients: null,
        allSubnets: null,
        instanceTypes: []
      });
    },

    actions: {
      getAuthCreds() {
        return this.authCreds();
      },

      addNodeGroup() {
        let {
          config
        } = this;
        let {
          nodeGroups = [],
          kubernetesVersion
        } = config;
        const ngConfig = { ..._cluster.DEFAULT_NODE_GROUP_CONFIG
        };
        Ember.set(ngConfig, 'launchTemplate', this.globalStore.createRecord({
          name: '',
          id: null,
          version: null,
          type: 'launchTemplate'
        }));

        if (!Ember.isArray(nodeGroups)) {
          nodeGroups = [];
        }

        const nodeGroup = this.globalStore.createRecord(ngConfig);
        Ember.set(nodeGroup, 'version', kubernetesVersion);
        nodeGroups.pushObject(nodeGroup);
        Ember.set(this, 'config.nodeGroups', nodeGroups);
      },

      removeNodeGroup(nodeGroup) {
        let {
          config: {
            nodeGroups = []
          }
        } = this;

        if (!Ember.isEmpty(nodeGroups)) {
          nodeGroups.removeObject(nodeGroup);
        }

        Ember.set(this, 'config.nodeGroups', nodeGroups);
      },

      setTags(section) {
        Ember.set(this, 'config.tags', section);
      },

      finishAndSelectCloudCredential(cred) {
        if (cred) {
          Ember.set(this, 'config.amazonCredentialSecret', Ember.get(cred, 'id'));
          this.send('startAwsConfiguration');
        }
      },

      multiSecurityGroupSelect() {
        let options = Array.prototype.slice.call((0, _jquery.default)('.existing-security-groups')[0], 0);
        let selectedOptions = [];
        options.filterBy('selected', true).forEach(cap => {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'config.securityGroups', selectedOptions);
      },

      multiSubnetGroupSelect() {
        let options = Array.prototype.slice.call((0, _jquery.default)('.existing-subnet-groups')[0], 0);
        let selectedOptions = [];
        options.filterBy('selected', true).forEach(cap => {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'config.subnets', selectedOptions);
      },

      async startAwsConfiguration(cb) {
        const errors = [];
        const {
          mode
        } = this;
        let step = 2;

        try {
          await Ember.RSVP.allSettled([this.loadRoles(), this.loadKMSKeys(), this.loadInstanceTypes(), this.loadLaunchTemplates(), this.loadKeyPairs()]);

          if (mode === 'edit') {
            step = 4;
          }

          Ember.set(this, 'step', step);

          if (cb) {
            cb();
          }

          return true;
        } catch (err) {
          errors.pushObject(err.message);
          Ember.set(this, 'errors', errors);
          return cb ? cb(false, err) : err;
        }
      },

      async loadSubnets(cb = function () {}) {
        const {
          selectedServiceRole
        } = this;

        if (!Ember.isEmpty(selectedServiceRole)) {
          Ember.set(this, 'config.serviceRole', selectedServiceRole);
        }

        try {
          const subnets = await this.describeResource(['EC2', 'describeSubnets', 'Subnets']);
          Ember.setProperties(this, {
            allSubnets: subnets,
            step: 3
          });
          cb();
        } catch (err) {
          Ember.get(this, 'errors').pushObject(err);
          cb(false, err);
        }
      },

      async setSubnets(cb) {
        try {
          if (Ember.get(this, 'vpcSubnetMode') === 'custom') {
            await this.loadSecurityGroups();
          }

          Ember.set(this, 'step', 4);
          cb();
        } catch (err) {
          Ember.get(this, 'errors').pushObject(err);
          cb(false, err);
        }
      }

    },
    clusterChanged: Ember.observer('originalCluster.state', function () {
      if (Ember.get(this, 'step') >= 2) {
        const state = Ember.get(this, 'originalCluster.state');

        if (!['active', 'pending', 'initializing', 'waiting'].includes(state)) {
          if (this.close) {
            this.close();
          }
        }
      }
    }),
    resetKms: Ember.observer('config.secretsEncryption', function () {
      if (!Ember.get(this, 'config.secretsEncryption')) {
        Ember.set(this, 'config.kmsKey', null);
      }
    }),
    cloudWatchLoggingChanged: Ember.observer('cloudWatchApiEnabled', 'cloudWatchAuditEnabled', 'cloudWatchAuthenticatorEnabled', 'cloudWatchControllerManagerEnabled', 'cloudWatchSchedulerEnabled', function () {
      let {
        config: {
          loggingTypes = []
        }
      } = this;
      const logOpts = ['audit', 'api', 'scheduler', 'controllerManager', 'authenticator'];

      if (!Ember.isArray(loggingTypes)) {
        loggingTypes = [];
      }

      logOpts.forEach(option => {
        const value = Ember.get(this, `cloudWatch${Ember.String.classify(option)}Enabled`);

        if (value) {
          loggingTypes.addObject(option);
        } else {
          loggingTypes.removeObject(option);
        }
      });
      Ember.set(this, 'config.loggingTypes', loggingTypes);
    }),
    serviceRoleModeDidChange: Ember.observer('serviceRoleMode', function () {
      const mode = Ember.get(this, 'serviceRoleMode');

      if (mode === 'custom') {
        const role = Ember.get(this, 'serviceRoles.firstObject.RoleName');

        if (role) {
          Ember.set(this, 'selectedServiceRole', role);
        }
      } else {
        Ember.set(this, 'selectedServiceRole', null);
      }
    }),
    postSaveChanged: Ember.observer('isPostSave', function () {
      const {
        isNew,
        isPostSave,
        config: {
          privateAccess,
          publicAccess
        }
      } = this;

      if (!publicAccess && privateAccess && isPostSave) {
        if (isNew) {
          Ember.set(this, 'step', 5);
        } else {
          this.close();
        }
      } else {
        this.close();
      }
    }),
    importedClusterIsPending: Ember.computed('clusterIsPending', 'model.originalCluster', function () {
      const {
        clusterIsPending
      } = this;
      const originalCluster = Ember.get(this, 'model.originalCluster');
      const ourClusterSpec = Ember.get(originalCluster !== null && originalCluster !== void 0 ? originalCluster : {}, 'eksConfig');
      const upstreamSpec = Ember.get(originalCluster !== null && originalCluster !== void 0 ? originalCluster : {}, 'eksStatus.upstreamSpec');
      return clusterIsPending && Ember.get(ourClusterSpec, 'imported') && !Ember.isEmpty(upstreamSpec);
    }),
    clusterIsPending: Ember.computed('clusterState', function () {
      const {
        clusterState
      } = this;
      return ['pending', 'provisioning', 'waiting'].includes(clusterState);
    }),
    cloudCredentials: Ember.computed('model.cloudCredentials', function () {
      const {
        model: {
          cloudCredentials
        }
      } = this;
      return cloudCredentials.filter(cc => !Ember.isEmpty(Ember.get(cc, 'amazonec2credentialConfig')));
    }),
    selectedCloudCredential: Ember.computed('config.amazonCredentialSecret', function () {
      const {
        model: {
          cloudCredentials = []
        },
        config: {
          amazonCredentialSecret
        }
      } = this;

      if (Ember.isEmpty(cloudCredentials) && Ember.isEmpty(amazonCredentialSecret)) {
        return null;
      } else {
        return cloudCredentials.findBy('id', amazonCredentialSecret.includes('cattle-global-data:') ? amazonCredentialSecret : `cattle-global-data:${amazonCredentialSecret}`);
      }
    }),
    selectedSubnets: Ember.computed('config.subnets.[]', 'primaryResource.eksStatus.subnets.[]', function () {
      const {
        config: {
          subnets
        },
        primaryResource: {
          eksStatus: {
            subnets: generatedSubnets
          } = {}
        }
      } = this;

      if (Ember.isEmpty(subnets)) {
        if (!Ember.isEmpty(generatedSubnets)) {
          return generatedSubnets;
        }

        return [];
      }

      return subnets;
    }),
    disableVersionSelect: Ember.computed('', 'config.kubernetesVersion', 'nodeGroupsVersionCollection', function () {
      const kubernetesVersion = Ember.get(this, 'config.kubernetesVersion');
      return Ember.get(this, 'nodeGroupsVersionCollection').any(version => (0, _semver.lt)((0, _semver.coerce)(version), (0, _semver.coerce)(kubernetesVersion)));
    }),
    nodeGroupsVersionCollection: Ember.computed('config.nodeGroups.@.eachversion', function () {
      return (Ember.get(this, 'config.nodeGroups') || []).map(ng => ng.version).uniq();
    }),
    versionChoices: Ember.computed('editing', 'kubernetesVersionContent', 'nodeGroupsVersionCollection.[]', function () {
      const {
        config,
        intl,
        kubernetesVersionContent,
        mode
      } = this;
      let {
        kubernetesVersion: initialVersion
      } = config;

      if (Ember.isEmpty(initialVersion)) {
        initialVersion = kubernetesVersionContent[0];
      }

      const versionChoices = this.versionChoiceService.parseCloudProviderVersionChoices(kubernetesVersionContent.slice(), initialVersion, mode); // only EKS and edit - user can only upgrade a single minor version at a time

      if (this.editing) {
        if (!versionChoices.findBy('value', initialVersion)) {
          versionChoices.unshift({
            label: initialVersion,
            value: initialVersion
          });
        }

        const initalMinorVersion = parseInt((0, _semver.minor)((0, _semver.coerce)(initialVersion)), 10);
        versionChoices.forEach(vc => {
          const vcMinorV = parseInt((0, _semver.minor)((0, _semver.coerce)(vc.value)), 10);
          const diff = vcMinorV - initalMinorVersion;

          if (diff > 1) {
            Ember.setProperties(vc, {
              disabled: true,
              label: `${vc.label} ${intl.t('formVersions.eks.label')}`
            });
          }
        });
      }

      return versionChoices;
    }),
    filteredKeyPairs: Ember.computed('allKeyPairs', function () {
      var _Ember$get;

      return ((_Ember$get = Ember.get(this, 'allKeyPairs')) !== null && _Ember$get !== void 0 ? _Ember$get : []).sortBy('KeyName');
    }),
    groupedSubnets: Ember.computed('filteredSubnets.[]', function () {
      const {
        filteredSubnets
      } = this;
      const out = [];
      filteredSubnets.forEach(subnet => {
        var vpc = Ember.get(subnet, 'group');

        if (vpc) {
          var group = out.filterBy('group', vpc)[0];

          if (!group) {
            group = {
              group: vpc,
              subnets: []
            };
            out.push(group);
          }

          group.subnets.push(subnet);
        }
      });
      return out.sortBy('group');
    }),
    filteredSubnets: Ember.computed('allSubnets', function () {
      return Ember.get(this, 'allSubnets').map(subnet => {
        return {
          subnetName: (0, _amazon.nameFromResource)(subnet, 'SubnetId'),
          subnetId: subnet.SubnetId,
          group: subnet.VpcId
        };
      }).sortBy('subnetName');
    }),
    filteredSecurityGroups: Ember.computed('allSecurityGroups', 'config.subnets.[]', function () {
      const {
        config: {
          subnets: configSubnets = []
        },
        filteredSubnets
      } = this;
      const selectedSubnets = filteredSubnets.filter(fs => configSubnets.includes(fs.subnetId));
      return Ember.get(this, 'allSecurityGroups').filter(sg => {
        return selectedSubnets.findBy('group', sg.VpcId);
      }).sortBy('GroupName');
    }),
    readableServiceRole: Ember.computed('config.serviceRole', 'serviceRoles', function () {
      const roles = Ember.get(this, 'serviceRoles');
      const selectedRole = Ember.get(this, 'config.serviceRole');
      const match = roles.findBy('RoleName', selectedRole);
      return match && match.RoleName ? Ember.get(match, 'RoleName') : this.intl.t('nodeDriver.amazoneks.role.noneSelected');
    }),

    syncUpstreamConfig() {
      const originalCluster = Ember.get(this, 'model.originalCluster').clone();
      const ourClusterSpec = Ember.get(originalCluster, 'eksConfig');
      const upstreamSpec = Ember.get(originalCluster, 'eksStatus.upstreamSpec');

      if (!Ember.isEmpty(upstreamSpec)) {
        Object.keys(ourClusterSpec).forEach(k => {
          if (Ember.isEmpty(Ember.get(ourClusterSpec, k)) && !Ember.isEmpty(Ember.get(upstreamSpec, k))) {
            Ember.set(this, `config.${k}`, Ember.get(upstreamSpec, k));
          }
        });
      }
    },

    authCreds() {
      let {
        config: {
          region,
          amazonCredentialSecret
        }
      } = this;
      const httpOptions = {
        cloudCredentialId: amazonCredentialSecret.includes('cattle-global-data') ? amazonCredentialSecret : `cattle-global-data:${amazonCredentialSecret}`
      };

      if (this.editing) {
        Ember.set(httpOptions, 'clusterID', Ember.get(this, 'model.originalCluster.id'));
      }

      const auth = {
        accessKeyId: (0, _util.randomStr)(),
        secretAccessKey: (0, _util.randomStr)(),
        region,
        httpOptions
      };
      return auth;
    },

    async loadRoles() {
      let eksRoles = [];

      try {
        const auth = { ...this.authCreds()
        };
        const {
          region
        } = auth;

        if (region === 'cn-northwest-1') {
          auth.region = 'cn-north-1';
          auth.endpoint = `iam.cn-north-1.amazonaws.com.cn`;
        }

        const awsRoles = await this.describeResource(['IAM', 'listRoles', 'Roles'], {}, auth);
        eksRoles = awsRoles.filter(role => {
          let policy = JSON.parse(decodeURIComponent(Ember.get(role, 'AssumeRolePolicyDocument')));
          let statement = Ember.get(policy, 'Statement');
          let isEksRole = false;
          statement.forEach(doc => {
            let principal = Ember.get(doc, 'Principal');

            if (principal) {
              let service = Ember.get(principal, 'Service');

              if (service && (service.includes('eks.amazonaws') || service.includes('EKS')) && !eksRoles.findBy('RoleId', Ember.get(role, 'RoleId'))) {
                isEksRole = true;
              } else if (Ember.get(principal, 'EKS')) {
                isEksRole = true;
              } else {
                isEksRole = false;
              }
            }
          });
          return isEksRole;
        });
        Ember.set(this, 'serviceRoles', eksRoles);
      } catch (err) {
        Ember.get(this, 'errors').pushObject(err);
      }
    },

    async loadSecurityGroups() {
      try {
        const securityGroups = await this.describeResource(['EC2', 'describeSecurityGroups', 'SecurityGroups']);
        Ember.set(this, 'allSecurityGroups', securityGroups);
      } catch (err) {
        Ember.get(this, 'errors').pushObject(err);
      }
    },

    async loadLaunchTemplates() {
      try {
        const launchTemplates = await this.describeResource(['EC2', 'describeLaunchTemplates', 'LaunchTemplates']);
        Ember.set(this, 'allLaunchTemplates', launchTemplates);
      } catch (err) {
        Ember.get(this, 'errors').pushObject(err);
      }
    },

    async loadInstanceTypes() {
      try {
        const instanceTypes = await this.describeResource(['EC2', 'describeInstanceTypes', 'InstanceTypes']);
        Ember.set(this, 'instanceTypes', instanceTypes);
      } catch (err) {
        Ember.get(this, 'errors').pushObject(err);
      }
    },

    async loadImages() {
      // this is can be VERY expensive if `ExecutableUsers` is excluded
      // I am not sure if that param is even correct nor do I think we should use this
      // I am leaving in for dev purpose remove before pr
      try {
        const images = await this.describeResource(['EC2', 'describeImages', 'Images'], {
          ExecutableUsers: ['self'],
          Filters: [{
            Name: 'state',
            Values: ['available']
          }]
        });
        Ember.set(this, 'allImages', images);
      } catch (err) {
        Ember.get(this, 'errors').pushObject(err);
      }
    },

    async loadKeyPairs() {
      try {
        const allKeyPairs = await this.describeResource(['EC2', 'describeKeyPairs', 'KeyPairs']);
        Ember.set(this, 'allKeyPairs', allKeyPairs);
      } catch (err) {
        Ember.get(this, 'errors').pushObject(err);
      }
    },

    async loadKMSKeys() {
      try {
        const kmsKeys = await this.describeResource(['KMS', 'listKeys', 'Keys']);
        Ember.set(this, 'kmsKeys', kmsKeys);
      } catch (err) {
        // creators MAY not have access to KMS keys via IAM so dont kill everything because they dont
        // its not required
        Ember.set(this, 'loadFailedKmsKeys', true);
      }
    },

    willSave() {
      this.validateNodeGroups();

      if (!Ember.isEmpty(this.errors)) {
        return false;
      }

      const {
        config
      } = this;
      const {
        displayName,
        subnets,
        nodeGroups
      } = config;

      if (Ember.isEmpty(subnets)) {
        Ember.set(this, 'config.subnets', []);
      }

      if (Ember.isEmpty(displayName) || displayName === '(null)') {
        Ember.set(this.config, 'displayName', this.primaryResource.name);
      }

      nodeGroups.forEach(ng => {
        const {
          launchTemplate
        } = ng;

        if (!Ember.isEmpty(launchTemplate)) {
          const {
            id,
            version,
            name
          } = launchTemplate;

          if (Ember.isEmpty(id) && Ember.isEmpty(version) && Ember.isEmpty(name)) {
            Ember.set(ng, 'launchTemplate', null);
          } // we dont want to send the example user data


          if (ng.userData === _cluster.DEFAULT_USER_DATA) {
            Ember.set(ng, 'userData', null);
          }
        }
      });
      return this._super(...arguments);
    },

    doSave(opt) {
      return Ember.get(this, 'primaryResource').save(opt).then(newData => {
        return newData;
      }).catch(err => {
        return err;
      });
    },

    validateNodeGroups() {
      const nodeGroups = Ember.get(this, 'primaryResource.eksConfig.nodeGroups');
      const errors = [];

      if (!Ember.isEmpty(nodeGroups)) {
        const nodeGroupErrors = [];
        nodeGroups.forEach(ng => {
          const ngErr = ng.validationErrors();
          nodeGroupErrors.push(ngErr);
        });

        if (!Ember.isEmpty(nodeGroupErrors)) {
          errors.pushObjects(nodeGroupErrors.flat());
        }
      }

      Ember.set(this, 'errors', errors);
      return errors.length >= 1 ? true : null;
    },

    describeResource(apiDescription, params = {}, authCreds = this.authCreds()) {
      const [awsClassName, awsSDKMethod, responseKey] = apiDescription;
      const klass = new AWS[awsClassName](authCreds);
      return new Ember.RSVP.Promise((resolve, reject) => {
        klass[awsSDKMethod](params, (err, data) => {
          if (err) {
            return reject(err);
          }

          return resolve(data[responseKey]);
        });
      });
    }

  });

  _exports.default = _default;
});